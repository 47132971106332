<template>
  <div class="scjg">
    <div class="left">
      <div class="top pub_border">
        <div class="pub_title priceRanking"><span>今日价格排行</span><el-select class="pub_input"
            v-model="markerPriceList.enterpriseInfoId" @change="changeMarkerName">
            <el-option v-for="item in allMarketList" :label="item" :value="item" :key="item"></el-option>
          </el-select></div>
        <div class="plant-rank-wrapper" v-infinite-scroll="todayPriceLoad" :infinite-scroll-distance="1"
          infinite-scroll-disabled="disabled">
          <div class="plant-rank" v-for="(item, index) in rankData" :key="index">
            <div class="info">
              <span class="name">{{ item.name }}</span>
              <span class="price">{{ item.avgPrice }}<span>{{ item.unit }}</span></span>
            </div>
            <div class="percent-wrapper">
              <div class="percent" :style="{ 'width': item.percent }"></div>
            </div>
          </div>
          <p v-if="loading">加载中...</p>
          <p v-if="noMore">没有更多了</p>
        </div>
      </div>
      <div class="bottom pub_border">
        <div class="pub_title">今日价格排行</div>
        <div class="chart" id="priceRank"></div>
      </div>
    </div>
    <div class="middle">
      <div class="pub_border">
        <div class="pub_title">市场价格</div>
        <div class="table-wrapper">
          <div class="operator">
            <el-input class="pub_input" v-model="allMarkerPrice.name" clearable placeholder="请输入品种"></el-input>
            <el-date-picker class="pub_input" v-model="allMarkerPrice.createTime" type="date" value-format="yyyy-MM-dd"
              placeholder="选择日期"></el-date-picker>
            <div class="btn" @click="searchTableData">
              <i class="el-icon-search"></i>
            </div>
          </div>
          <div class="table">
            <div class="th">
              <div>品种</div>
              <div>规格</div>
              <div>均价</div>
              <div>发布时间</div>
              <div>市场名称</div>
            </div>
            <div class="td" v-infinite-scroll="allPriceLoad" :infinite-scroll-distance="1"
              infinite-scroll-disabled="allDisabled">
              <ul v-for="(item, index) in tableData" :key="index" :class="{ active: activeIndex == index }"
                @click="tabVegetablesType(item, index)">
                <li>{{ item.name }}</li>
                <li>{{ item.unit }}</li>
                <li>{{ item.avgPrice }}</li>
                <li>{{ item.createTime }}</li>
                <li>{{ item.enterpriseInfoId }}</li>
              </ul>
              <p v-if="allLoading">加载中...</p>
              <p v-if="allNoMore">没有更多了</p>
            </div>
          </div>
        </div>
        <div class="charts">
          <div class="info">
            <div class="name">{{ historyPrice.name }}近一月价格走势图</div>
          </div>
          <div class="chart" id="priceChart"></div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="top">
        <map-chart ref="marketMapRef" height="520px" :chart-option="chartOption"
          :allMarketList="allMarketList"></map-chart>
      </div>
      <!-- <div class="price_title">{{ this.marketRankDataQuery.enterpriseInfoId }}今日价格排行</div> -->
      <div class="bottom" v-infinite-scroll="markerLoad" :infinite-scroll-distance="1"
        infinite-scroll-disabled="markerDisabled">
        <div class="rank" :class="'rank' + (index + 1)" v-for="(item, index) in marketRankData" :key="index"
          @click="lookDetail(item, index)">
          <div>NO.{{ index + 1 }}</div>
          <div :title="item.name">{{ item.name }}</div>
          <div>{{ item.avgPrice }}<span>{{ item.unit }}</span></div>
          <div>{{ item.enterpriseInfoId }}</div>
        </div>
        <p v-if="markerLoading">加载中...</p>
        <p v-if="markerNoMore">没有更多了</p>
      </div>
    </div>
    <base-dialog width="1000px" height="500px" label="数据详情" v-show="dialogFlag" :show-close="true"
      @closeDialog="closeDialog">
      <ul class="itemDetail">
        <li>价格排行：{{ this.itemDetailIndex || '' }}</li>
        <li>品种：{{ this.itemDetail.name || '' }}</li>
        <li>单价（元）：{{ this.itemDetail.avgPrice || '' }}</li>
        <li>规格：{{ this.itemDetail.unit || '' }}</li>
        <!-- <li>产地：{{ this.itemDetail.region || '' }}</li> -->
        <li>市场：{{ this.itemDetail.enterpriseInfoId || '' }}</li>
      </ul>
    </base-dialog>
  </div>
</template>

<script>
import {
  getTodayList,
  getTableData,
  getPlantChart,
  getMarkerList,
  getAllMarketList,
  getMarketList,
} from '@/api/scjg'
import mapChart from '../../components/map/chinaMap'
import BaseDialog from '@/components/dialog/baseDialog' //品种弹框
import { ERR_OK } from '../../utils/config'
export default {
  name: 'Scjg',
  components: { mapChart, BaseDialog },
  data() {
    return {
      rankData: [],
      varietyData: [],
      tableData: [],
      selectRowData: {},
      marketRankDataQuery: {
        pageNum: 1,
        pageSize: 1000000,
        name: '',
        createTime: '',
      },
      marketRankData: [],
      marketRankDataTotal: 0,
      markerLoading: false,
      allMarketList: [],
      markerPriceList: {
        pageNum: 1,
        pageSize: 10,
        enterpriseInfoId: '',
      },
      markerPriceListToatl: 0,
      allMarkerPrice: {
        pageNum: 1,
        pageSize: 10,
        name: '',
        createTime: '',
      },
      allMarkerPriceTotal: 0,
      loading: false,
      allLoading: false,
      xData: [],
      yData: [],
      historyPrice: {
        pageNum: 1,
        pageSize: 30,
        name: '',
        enterpriseInfoId: '',
      },
      activeIndex: 0,
      itemDetail: {},
      itemDetailIndex: 0,
      dialogFlag: false,
      chartOption: {
        tooltip: (params) => {
          let data = params.data
          let html = `<div style="padding:0;width: 280px;height: 90px;background: rgba(2,18,81,0.68);">
                        <div style="width: 254px;height: 23px;line-height: 23px;text-indent: 1em;font-size: 13px;color: #fff;background: linear-gradient(90deg,rgba(45,125,255,1), rgba(45,125,255,0))">${data.name
            }</div>
                        <div style="margin-left: 1em;line-height: 30px"><span>品种：<span style="color:#0CFED9;font-size: 18px">${data.names
            }</span></span></div>
                        <div style="margin-left: 1em;line-height: 30px"><span>价格：<span style="color:#0CFED9;font-size: 18px">${data.price || 0
            }${data.unit}</span></span></div>
                    </div>`
          return html
        },
      },
    }
  },
  mounted() {
    this.init()
    this.initPriceRank()
    this.initPriceChart()
  },
  computed: {
    noMore() {
      // 当起始页数大于等于总页数时停止加载
      return this.rankData.length >= this.markerPriceListToatl;
    },
    disabled() {
      return this.loading || this.noMore
    },
    allNoMore() {
      // 当起始页数大于等于总页数时停止加载
      return this.tableData.length >= this.allMarkerPriceTotal;
    },
    allDisabled() {
      return this.allLoading || this.allNoMore
    },
    markerNoMore() {
      // 当起始页数大于等于总页数时停止加载
      return this.marketRankData.length >= this.marketRankDataTotal;
    },
    markerDisabled() {
      return this.allLoading || this.markerNoMore
    },
  },
  methods: {
    async init() {
      let date = new Date()
      let mon =
        (date.getMonth() + 1).toString().length === 1
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1
      let day =
        date.getDate().toString().length === 1
          ? '0' + date.getDate()
          : date.getDate()
      this.date = `${date.getFullYear()}-${mon}-${day}`
      await this.getAllMarketList();
      await this.getTodayList()
      await this.getTableData()
      this.historyPrice.name = this.tableData[0].name;
      await this.getPlantChart();
      this.marketRankData = [];
      this.marketRankDataQuery.name = this.tableData[0].name;
      this.marketRankDataQuery.createTime = this.tableData[0].createTime;
      await this.getMarketList()
      // await this.getMarkerList()
    },
    // 获取今日市场价格
    getTodayList() {
      return getTodayList({ ...this.markerPriceList }).then((res) => {
        if (res.code === ERR_OK) {
          let data = res.rows;
          this.rankData = [...this.rankData, ...data];
          this.rankData.sort((a, b) => b.avgPrice - a.avgPrice);
          let xData = [];
          let yData = [];
          this.rankData.forEach((item) => {
            item.percent = ((item.avgPrice / this.rankData[0].avgPrice) * 100).toFixed(2) + '%'
            xData.push(item.name);
            yData.push(item.avgPrice);
          })
          this.markerPriceListToatl = res.total;
          this.loading = false;
          this.initPriceRank(xData, yData);
        }
      }).catch(() => {
        this.loading = false;
      })
    },
    //查询市场列表
    getAllMarketList() {
      return getAllMarketList().then((res) => {
        if (res.code === ERR_OK) {
          this.allMarketList = res.rows
          this.markerPriceList.enterpriseInfoId = res.rows[0]
        }
      })
    },
    //切换市场
    changeMarkerName(event) {
      this.markerPriceList.enterpriseInfoId = event
      this.rankData = [];
      this.getTodayList();
    },
    todayPriceLoad() {
      this.loading = true
      setTimeout(() => {
        this.markerPriceList.pageNum += 1; //滚动条到底时，页码自增 1
        this.getTodayList(); //调用接口
      }, 500)
    },
    // 获取表格数据
    getTableData() {
      return getTableData({
        ...this.allMarkerPrice
      }).then((res) => {
        if (res.code === ERR_OK) {
          this.tableData = [...this.tableData, ...res.rows]
          if (res.rows.length > 0) {
            // this.$refs.table.setCurrentRow(res.rows[0])
            // if (this.activeIndex != 0) {
            //   this.selectRow(this.tableData[this.activeIndex]);
            // } else {
            //   this.selectRow(this.tableData[0]);
            // }
          }
          this.allMarkerPriceTotal = res.total;
          this.allLoading = false;
        }
      }).catch(() => {
        this.allLoading = false;
      })
    },
    allPriceLoad() {
      this.allLoading = true;
      setTimeout(() => {
        this.allMarkerPrice.pageNum += 1; //滚动条到底时，页码自增 1
        this.getTableData(); //调用接口
      }, 500)
    },
    searchTableData() {
      this.allMarkerPrice.pageNum = 1;
      this.allMarkerPrice.pageSize = 10;

      return getTableData({
        ...this.allMarkerPrice
      }).then((res) => {
        if (res.code === ERR_OK) {
          this.tableData = res.rows;
          if (res.rows.length > 0) {
            this.selectRow(this.tableData[0]);
            this.activeIndex = 0;

            this.marketRankData = [];
            this.marketRankDataQuery.name = this.tableData[0].name;
            this.marketRankDataQuery.createTime = this.tableData[0].createTime;
            this.getMarketList()
          }
        }
      })
    },
    // 表格选择
    selectRow(row) {
      this.selectRowData = row;
      this.historyPrice.enterpriseInfoId = row.enterpriseInfoId;
      this.historyPrice.name = row.name;
      this.getPlantChart();

      // this.marketRankData = [];
      // this.marketRankDataQuery.name = row.name;
      // this.marketRankDataQuery.createTime = row.createTime;

      // getMarketList({
      //   ...this.marketRankDataQuery
      // }).then(res => {
      //   if (res.code === ERR_OK) {
      //     this.marketRankData = [...this.marketRankData, ...res.rows];
      //     this.marketRankDataTotal = res.total;
      //     this.markerLoading = false;
      //   }
      // }).catch(() => {
      //   this.markerLoading = false;
      // })
      // this.getMarkerList();
    },
    // 获取蔬菜近一个月数据
    getPlantChart() {
      return getPlantChart({
        ...this.historyPrice
      }).then((res) => {
        if (res.code === ERR_OK) {
          // this.priceChartReload(res.rows)
          this.initPriceChart(res.rows)
        }
      })
    },
    // 获取蔬菜图表
    tabVegetablesType(item, index) {
      this.activeIndex = index;
      this.historyPrice.name = item.name;
      this.getPlantChart();

      this.marketRankData = [];
      this.marketRankDataQuery.name = item.name;
      this.marketRankDataQuery.createTime = item.createTime;
      this.getMarketList();
    },
    initPriceRank(xData, yData) {
      // console.log(xData, yData, this.xData, this.yData, 'ffffffffffff');
      //市场价格排行
      this.priceRank = this.$echarts.init(document.getElementById('priceRank'))
      let fontColor = '#B9C3D3'
      let option = {
        backgroundColor: 'transparent',
        grid: {
          left: '7%',
          right: '2%',
          top: '12%',
          bottom: '15%',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            lineStyle: {
              color: fontColor,
            },
          },
        },
        legend: {
          data: [],
          right: '8%',
          top: '2%',
          textStyle: {
            color: fontColor,
          },
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLine: {
            show: true,
            lineStyle: {
              color: fontColor,
            },
          },
          offset: 0,
          axisTick: {
            show: false,
            alignWithLabel: true,
            lineStyle: {
              color: fontColor,
            },
          },
          axisLabel: {
            fontSize: 16,
            color: fontColor,
          },
          // data: [],
          data: xData,
        },
        yAxis: {
          name: '元/公斤',
          nameTextStyle: {
            color: fontColor,
          },
          type: 'value',
          min: 0,
          axisLine: {
            show: true,
            lineStyle: {
              color: fontColor,
            },
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dotted',
              color: fontColor,
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 16,
            color: fontColor,
          },
          boundaryGap: ['20%', '20%'],
        },
        series: [
          {
            name: '',
            type: 'line',
            smooth: true,
            symbol: 'none',
            showSymbol: false,
            symbolSize: 8,
            itemStyle: {
              color: '#0BFFD5',
            },
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(11,255,213,0.4)',
                },
                {
                  offset: 1,
                  color: 'rgba(11,255,213,0)',
                },
              ]),
            },
            // data: [],
            data: yData,
          },
        ],
      }
      this.priceRank.setOption(option)
    },
    initPriceChart(data) {
      // console.log(data, 'ppppppppppppppppppppppppp');
      let xData = [];
      let yData = [];
      if (data) {
        data.forEach((item) => {
          xData.push(item.createTime);
          yData.push(item.avgPrice);
        })
      }
      //市场价格排行
      this.priceChart = this.$echarts.init(
        document.getElementById('priceChart')
      )
      let fontColor = '#B9C3D3'
      let option = {
        backgroundColor: 'transparent',
        grid: {
          left: '6%',
          right: '2%',
          top: '12%',
          bottom: '15%',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            lineStyle: {
              color: fontColor,
            },
          },
        },
        legend: {
          data: [this.historyPrice.name],
          right: '8%',
          top: '2%',
          textStyle: {
            color: fontColor,
          },
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          axisLine: {
            show: true,
            lineStyle: {
              color: fontColor,
            },
          },
          offset: 0,
          axisTick: {
            show: false,
            alignWithLabel: true,
            lineStyle: {
              color: fontColor,
            },
          },
          axisLabel: {
            fontSize: 16,
            color: fontColor,
          },
          data: xData,
        },
        yAxis: {
          name: '元/斤',
          nameTextStyle: {
            color: fontColor,
          },
          type: 'value',
          min: 0,
          axisLine: {
            show: true,
            lineStyle: {
              color: fontColor,
            },
          },
          splitLine: {
            show: false,
            lineStyle: {
              type: 'dotted',
              color: fontColor,
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontSize: 16,
            color: fontColor,
          },
          boundaryGap: ['20%', '20%'],
        },
        series: [
          {
            name: this.historyPrice.name,
            type: 'line',
            smooth: true,
            symbol: 'none',
            showSymbol: false,
            symbolSize: 8,
            itemStyle: {
              color: '#FFBA00',
            },
            areaStyle: {
              opacity: 0.8,
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(255,186,0,0.4)',
                },
                {
                  offset: 1,
                  color: 'rgba(255,186,0,0)',
                },
              ]),
            },
            data: yData,
          },
        ],
      }
      this.priceChart.setOption(option)
    },
    // 获取蔬菜地图数据
    getMarkerList() {
      return getMarkerList().then((res) => {
        if (res.code === ERR_OK) {
          let markers = [];
          this.marketRankData.forEach(item => {
            markers.push(item);
          })

          let datas = [];
          for (let i = 0; i < markers.length; i++) {
            for (let j = 0; j < res.rows.length; j++) {
              if (res.rows[j].name == markers[i].enterpriseInfoId) {
                // console.log(1111111111111);
                datas.push({ ...res.rows[j], names: markers[i].name, price: markers[i].avgPrice, unit: markers[i].unit });
              }
            }
          }

          this.$refs.marketMapRef.addMarker([
            {
              name: '市场价格',
              url: `image://${require('@/assets/images/ssjg_marker.png')}`,
              size: [29, 38],
              data: datas,
              //                        data: [{
              //                            name: '市场一',
              //                            value: [119.596224,39.943458],
              //                            market: 23
              //                        }, {
              //                            name: '市场二',
              //                            value: [114.548151, 38.047501],
              //                            market: 23
              //                        }]
            },
          ])
          // this.getMarketList();
        }
      })
    },
    // 获取某个蔬菜各个市场价格
    getMarketList() {
      return getMarketList({
        ...this.marketRankDataQuery,
        accurateName: this.marketRankDataQuery.name,
        name: ''
      }).then(res => {
        // console.log(res.rows, '0000000000000000000000');
        if (res.code === ERR_OK) {
          this.marketRankData = [...this.marketRankData, ...res.rows];
          // this.marketRankData.forEach((item) => {
          //   if (item.unit === "元/公斤") {
          //     const price = parseFloat(item.avgPrice);
          //     item.avgPrice = (price / 2).toFixed(2);
          //     item.unit = "元/斤";
          //   }
          // })
          // this.marketRankData.sort((a, b) => b.avgPrice - a.avgPrice)

          this.marketRankDataTotal = res.total;
          this.markerLoading = false;
          this.getMarkerList();
        }
      }).catch(() => {
        this.markerLoading = false;
      })
    },
    markerLoad() {
      this.markerLoading = true;
      setTimeout(() => {
        this.marketRankDataQuery.pageNum += 1; //滚动条到底时，页码自增 1
        // this.getMarketList(); //调用接口
      }, 500)
    },
    //查看详情
    lookDetail(row, index) {
      this.itemDetail = row;
      this.itemDetailIndex = index + 1;
      this.dialogFlag = true;
    },
    closeDialog() {
      this.dialogFlag = false;
    },
  },
}
</script>
<style lang='scss' scoped>
.scjg {
  display: flex;
  padding: 0 40px;
  box-sizing: border-box;

  .pub_border {
    background: linear-gradient(180deg,
        rgba(14, 47, 82, 0),
        rgba(14, 47, 82, 0.76));
  }

  .left,
  .right {
    width: 500px;

    .top {
      height: 520px;
      margin-bottom: 30px;
    }
  }

  .left {
    .top {
      .priceRanking {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .plant-rank-wrapper {
        height: 460px;
        overflow-y: auto;

        /* 设置滚动条的宽度 */
        &::-webkit-scrollbar {
          width: 0px;
        }

        ::v-deep .el-scrollbar__wrap {
          overflow-x: hidden;
        }

        >p {
          text-align: center;
        }
      }

      .plant-rank {
        height: 45px;
        margin: 8px 18px 0;

        .info {
          display: flex;
          justify-content: space-between;
          margin-bottom: 4px;

          .name {
            color: #fff;
            font-size: 16px;
          }

          .price {
            color: #00ffff;
            font-size: 20px;
          }
        }

        .percent-wrapper {
          height: 14px;
          background: rgba(17, 67, 175, 0.29);
          transform: skew(-40deg);

          .percent {
            background: linear-gradient(90deg, rgba(3, 131, 251, 0), #0383fb);
            height: 14px;
            position: relative;

            &:before {
              content: '';
              position: absolute;
              right: 0;
              width: 2px;
              height: 11px;
              background: linear-gradient(0deg, #d7ffff, #abffff);
            }
          }
        }
      }
    }

    .bottom {
      height: 310px;
      overflow: hidden;

      .chart {
        height: 263px;
      }
    }
  }

  .middle {
    flex: 1;
    margin: 0 28px;

    .pub_border {
      height: 860px;
      padding: 0 20px;
      box-sizing: border-box;
    }

    .info {
      height: 30px;
      position: relative;
      margin: 10px 40px;
      display: flex;
      font-size: 16px;
      color: #fff;
      justify-content: space-around;
      box-sizing: border-box;
      padding: 0 80px;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform-origin: bottom;
        transform: perspective(100px) scale(1.1, 1.3) rotateX(18deg);
        background: linear-gradient(180deg,
            rgba(79, 91, 254, 0),
            rgba(12, 92, 255, 0.42));
        box-shadow: 0px 7px 7px 0px rgba(1, 7, 38, 0.4);
      }

      .price {
        color: #ffca28;
      }

      .yoy,
      .qoq {
        span {
          color: #ff8611;
        }
      }
    }

    .table-wrapper {
      height: 518px;

      .operator {
        display: flex;
        height: 88px;
        align-items: center;
        justify-content: center;

        .el-select {
          margin-right: 30px;
          width: 200px;
        }

        .el-input {
          margin-right: 30px;
          width: 280px;
        }

        .btn {
          width: 53px;
          height: 40px;
          background: #1890ff;
          line-height: 40px;
          text-align: center;
          color: #fff;
          font-size: 22px;
          cursor: pointer;
        }
      }

      .table {
        .th {
          background-color: #07519e;
        }

        .td {
          height: 370px;
          overflow-y: auto;

          /* 设置滚动条的宽度 */
          &::-webkit-scrollbar {
            width: 0px;
          }

          cursor: pointer;
        }

        .th,
        .td ul {
          display: flex;
          align-items: center;
          text-align: center;

          >div,
          li {
            flex: 1;
            height: 45px;
            line-height: 45px;
          }

          >div:last-child,
          li:last-child {
            flex: 1.5;
            overflow: hidden;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            -webkit-text-overflow: ellipsis;
            -moz-text-overflow: ellipsis;
            white-space: nowrap;
          }

        }

        .td {
          >p {
            text-align: center;
            margin-top: 5px;
          }
        }

        ul:nth-child(2n) {
          background-color: #115598;
        }

        ul:hover {
          background-color: #0969c5;
        }

        ul.active {
          background-color: #0970a0;
        }
      }
    }

    .chart {
      height: 263px;
    }
  }

  .right {
    // .price_title {
    //   height: 47px;
    //   line-height: 47px;
    //   font-size: 22px;
    //   letter-spacing: 3px;
    //   font-weight: 700;
    //   box-sizing: border-box;
    //   overflow: hidden; //超出宽度隐藏
    //   white-space: nowrap; //强制文字在一行
    //   text-overflow: ellipsis; //文字溢出显示省略号
    // }

    .bottom {
      height: 310px;
      overflow-y: auto;

      /* 设置滚动条的宽度 */
      &::-webkit-scrollbar {
        width: 0px;
      }

      ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
      }

      .rank {
        display: flex;
        position: relative;
        height: 35px;
        line-height: 35px;
        box-sizing: border-box;
        // padding: 0 25px;
        font-size: 16px;
        background: rgba(21, 78, 149, 0.16);
        justify-content: space-around;
        margin-bottom: 9px;
        cursor: pointer;

        &:before {
          content: '';
          width: 2px;
          height: 13px;
          border-left: 2px solid #2cc6ff;
          position: absolute;
          left: 0;
          top: 11px;
          box-sizing: border-box;
        }

        &>div {
          text-align: center;
        }

        &>div:nth-child(1),
        &>div:nth-child(3),
        &>div:nth-child(4) {
          color: #2cc6ff;
        }

        &>div:nth-child(1) {
          width: 60px;
        }

        &>div:nth-child(2) {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &>div:nth-child(3) {
          width: 120px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;
        }

        &>div:nth-child(4) {
          width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;
        }

        &.rank1 {
          background: rgba(154, 85, 18, 0.16);

          &:before {
            border-left: 2px solid #ff8611;
          }

          &>div:nth-child(1),
          &>div:nth-child(3),
          &>div:nth-child(4) {
            color: #ff8611;
          }
        }

        &.rank2 {
          background: rgba(148, 115, 16, 0.16);

          &:before {
            border-left: 2px solid #ffca28;
          }

          &>div:nth-child(1),
          &>div:nth-child(3),
          &>div:nth-child(4) {
            color: #ffca28;
          }
        }

        &.rank3 {
          background: rgba(22, 174, 90, 0.16);

          &:before {
            border-left: 2px solid #5ae799;
          }

          &>div:nth-child(1),
          &>div:nth-child(3),
          &>div:nth-child(4) {
            color: #5ae799;
          }
        }

      }

      >p {
        text-align: center;
      }
    }
  }

  .itemDetail {
    padding: 30px 50px;

    li {
      line-height: 35px;
      font-size: 20px;
    }
  }
}
</style>