import { request } from '@/utils/axios'

// 市场价格
// 每日价格
export const getTodayList = (params) => {
    return request({
        method: 'get',
        url: `${cxdjcs}/business/basePrice/priceRanking`,
        params
    })
}

// 获取蔬菜价格列表
export const getTableData = (params) => {
    return request({
        method: 'get',
        url: `${cxdjcs}/business/basePrice/page`,
        params
    })
}

// 获取蔬菜近一个月数据
export const getPlantChart = (params) => {
    return request({
        method: 'get',
        url: `${cxdjcs}/business/basePrice/list`,
        params
    })
}

// 获取蔬菜地图数据
export const getMarkerList = (params) => {
    return request({
        method: 'get',
        url: `${cxdjcs}/business/basePrice/getMarketCoordinateList`,
        params
    })
}

// 获取某个蔬菜在各个市场的价格表
export const getMarketList = (params) => {
    return request({
        method: 'get',
        url: `${cxdjcs}/business/basePrice/page`,
        params
    })
}

// 获取市场列表
export const getAllMarketList = (params, headers) => {
    return request({
        method: 'get',
        url: `${cxdjcs}/business/basePrice/getMarketList`,
        headers,
        params
    })
}

// 获取品种列表
export const getPlantsList = (params, headers) => {
    return request({
        method: 'get',
        url: `${cxdjcs}/business/basePrice/getPlantList`,
        headers,
        params
    })
}
