<template>
  <div class="map-wrapper" :style="{ 'height': height }">
    <!-- <div class="back" v-show="hasDrop && drop" @click="back"></div> -->
    <div class="map" id="Map"></div>
  </div>
</template>

<script type="text/ecmascript-6">
import areaCode from '../../../public/config/area.json'
import countyCode from '../../../public/config/county.json'
import hebei from '@/assets/map/map-hebei.json'
import china from '@/assets/map/china.json'
import county from '@/assets/map/map-county.json'
import bd from '@/assets/map/bd.json'
import cd from '@/assets/map/cd.json'
import cz from '@/assets/map/cz.json'
import hd from '@/assets/map/hd.json'
import hs from '@/assets/map/hs.json'
import lf from '@/assets/map/lf.json'
import qhd from '@/assets/map/qhd.json'
import sjz from '@/assets/map/sjz.json'
import ts from '@/assets/map/ts.json'
import xt from '@/assets/map/xt.json'
import zjk from '@/assets/map/zjk.json'

const geoJSON = {
  保定市: bd,
  承德市: cd,
  沧州市: cz,
  邯郸市: hd,
  衡水市: hs,
  廊坊市: lf,
  秦皇岛市: qhd,
  石家庄市: sjz,
  唐山市: ts,
  邢台市: xt,
  张家口市: zjk,
}

let countyJson = {
  type: 'map',
  map: 'county',
  aspectScale: 0.88,
  roam: false,
  scaleLimit: {
    min: 1,
  },
  z: 3,
  animationDurationUpdate: 0,
  label: {
    normal: {
      show: false,
      textStyle: {
        color: '#fff',
        fontSize: '17',
      },
    },
    emphasis: {
      textStyle: {
        color: '#fff',
        fontSize: '17',
      },
    },
  },
  itemStyle: {
    normal: {
      borderColor: 'rgba(162,255,0,0.61)',
      areaColor: 'rgba(162,255,0,0.61)',
    },
  },
  emphasis: {
    itemStyle: {
      borderColor: 'rgba(255,117,63,0.63)',
      areaColor: 'rgba(255,117,63,0.63)',
    },
  },
}

export default {
  props: {
    hasDrop: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '664px',
    },
    chartOption: {
      type: Object,
      default: () => {
        return {}
      },
    },
    hasCounty: {
      type: Boolean,
      default: false,
    },
    geoData: {
      type: Array,
      default: () => [],
    },
    countySelect: {
      type: Boolean,
      default: false,
    },
    allMarketList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      drop: false,
    }
  },
  mounted() {
    this.initMap()
  },
  methods: {
    initMap() {
      this.$echarts.registerMap('中国', china)
      this.$echarts.registerMap('county', county)
      this.mapChart = this.$echarts.init(document.getElementById('Map'))
      this.option = {
        tooltip: {},
        geo: [
          {
            map: '中国',
            layoutCenter: ['50%', '50%'],
            layoutSize: this.chartOption.layoutSize || '100%',
            label: {
              normal: {
                show: false,
                textStyle: {
                  color: '#fff',
                  fontSize: '17',
                  backgroundColor: 'rgba(0,96,255,0.5)',
                  lineHeight: 32,
                  padding: [0, 8],
                  borderWidth: 1,
                  borderColor: 'rgba(0,96,255,1)',
                },
              },
              emphasis: {
                show: false,
                textStyle: {
                  color: '#fff',
                  fontSize: '17',
                  backgroundColor: 'rgba(255,117,63,0.63)',
                  lineHeight: 32,
                  padding: [0, 8],
                  borderWidth: 1,
                  borderColor: 'rgba(255,117,63,1)',
                },
              },
            },
            roam: true, //是否允许缩放
            aspectScale: 0.8,
            scaleLimit: {
              min: 1,
            },
            selectedMode: 'single',
            itemStyle: {
              normal: {
                borderColor: '#B5E9FB',
                borderWidth: 2,
                areaColor: {
                  type: 'radial',
                  x: 0.6,
                  y: 0.6,
                  r: 3,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(95,197,255, 0)', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(95,197,255, 1)', // 100% 处的颜色
                    },
                  ],
                  globalCoord: true,
                },
                shadowColor: 'rgb(21,171,246)',
                shadowOffsetX: 0,
                shadowOffsetY: 6,
                shadowBlur: 4,
              },
            },
            emphasis: {
              itemStyle: {
                borderColor: 'rgba(229,185,8, 1)',
                borderWidth: 2,
                areaColor: {
                  type: 'radial',
                  x: 0.6,
                  y: 0.6,
                  r: 3,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(95,197,255, 0)', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(95,197,255, 1)', // 100% 处的颜色
                    },
                  ],
                  globalCoord: true,
                },
                shadowColor: 'rgba(229,185,8, 1)',
                shadowOffsetX: 2,
                shadowOffsetY: 4,
                shadowBlur: 4,
              },
            },
            select: {
              itemStyle: {
                borderColor: 'rgba(229,185,8, 1)',
                borderWidth: 2,
                areaColor: {
                  type: 'radial',
                  x: 0.6,
                  y: 0.6,
                  r: 3,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(95,197,255, 0)', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(95,197,255, 1)', // 100% 处的颜色
                    },
                  ],
                  globalCoord: true,
                },
                shadowColor: 'rgba(229,185,8, 1)',
                shadowOffsetX: -2,
                shadowOffsetY: 4,
                shadowBlur: 10,
              },
            },
            tooltip: {
              show: true,
              formatter: (params) => {
                return this.chartOption.tooltip
                  ? this.chartOption.tooltip(params)
                  : params.name
              },
            },
          },
        ],
        series: [],
      }
      this.mapChart.setOption(this.option, true)
      this.mapChart.on('click', (params) => {
        let index = this.allMarketList.findIndex(item => item == params.name);
        if (index != -1) {
          // this.$emit('changeMarker', params.name);
        }
      })

      // this.mapChart.on('georoam', (params) => {
      //   if (!this.hasCounty) return
      //   let option = this.mapChart.getOption()
      //   if (params.zoom !== null && params.zoom !== undefined) {
      //     option.geo[1].zoom = option.geo[0].zoom
      //     option.geo[1].center = option.geo[0].center
      //   } else {
      //     option.geo[1].center = option.geo[0].center
      //   }
      //   this.mapChart.setOption(option, true)
      // })
      // this.mapChart.on('mousemove', (params) => {
      //   if (params.componentType === 'geo') {
      //     this.$emit('geoMousemove', {
      //       pageX: params.event.event.pageX + 50,
      //       pageY: params.event.event.pageY,
      //       name: params.name,
      //     })
      //     //                    console.log(params,params.event.event.pageX,params.event.event.pageY)
      //   }
      // })
    },
    addMarker(markers) {
      let series = []
      markers.forEach((marker) => {
        let { data, name, url, size } = marker
        let markers = {
          name,
          type: 'scatter',
          coordinateSystem: 'geo',
          symbol: url,
          symbolSize: size,
          itemStyle: {
            opacity: 1,
          },
          data,
          z: 200,
          zLevel: 200,
        }
        series.push(markers)
      })
      if (!series.length) {
        series = [
          {
            data: [],
          },
        ]
      }
      this.mapChart.clear()
      this.option.series = series

      this.mapChart.setOption(this.option, true)
      this.option = this.mapChart.getOption()
    },
    // back() {
    //   this.drop = false
    //   this.option.geo[0].map = '中国'
    //   // 包含则返回加上区县地图数据
    //   if (this.hasCounty) {
    //     this.option.geo.push(countyJson)
    //   }
    //   this.mapChart.setOption(this.option, true)
    //   this.$emit('selectCity', {
    //     name: '河北省',
    //     code: '',
    //   })
    // },
    setGeoSetting(data) {
      let customSetting = []
      if (Array.isArray(data) && data.length) {
        data
          .sort((a, b) => a.plantnum - b.plantnum)
          .forEach((item, index) => {
            let color = `rgba(5,173,90, 0.5)`
            // let color = `rgba(5,173,90, ${(index + 1) * 0.1})`
            customSetting.push({
              name: item.areaName,
              itemStyle: {
                borderColor: '#B5E9FB',
                areaColor: color,
                shadowColor: 'rgba(5,173,90,1)',
              },
              emphasis: {
                show: false,
              },
            })
          })
      }
      this.option.geo[0].regions = customSetting
    },
  },
  watch: {
    geoData: {
      //监听的对象
      deep: true, //深度监听设置为 true
      handler: function () {
        this.setGeoSetting(JSON.parse(JSON.stringify(this.geoData)))
        this.mapChart.setOption(this.option, true)
      },
    },
  },
}
</script>

<style scoped lang='scss'>
.map-wrapper {
  position: relative;

  .back {
    position: absolute;
    width: 179px;
    height: 51px;
    background: url('back.png') no-repeat center/cover;
    cursor: pointer;
    z-index: 100;
    top: 10px;
    left: 10px;
  }

  .map {
    height: 100%;
  }
}
</style>
