<template>
    <!-- 公共弹框 -->
    <div class="base_dialog">
        <div class="shade" v-if="shade"></div>
        <div class="dialog_wrap" :style="{width:width,height:height,zIndex:zIndex}">
            <div class="dialog_header">
                <h3 class="dialog_title">
                    <!-- <img src="./../../assets/images/logo.png" alt=""> -->
                    <span :title="label">{{label}}</span>
                </h3>
                <div v-if="showClose" class="dialog_close" @click="closeDialog">×</div>
            </div>
            <div class="dialog_body">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'BaseDialog',
        props:{
            width:{
                type:String,
                default:'1000px',
            },
            height:{
                type:String,
                default:'800px',
            },
            shade:{
                type:Boolean,
                default:true,
            },
            showClose:{
                type:Boolean,
                default:true,
            },
            label:{
                type:String,
                default:'类型管理',
            },
            zIndex:{
                type:Number,
                default:103,
            },
        },
        methods:{
            closeDialog(){//关闭弹框
                this.$emit('closeDialog')
            }
        }
    }
</script>

<style lang='scss' scoped>
    .base_dialog{
        .shade{
            content: '';
            display: block;
            position: fixed;
            top:0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 102;
            background: linear-gradient(0deg, rgba(1,8,19,.82) 0%, rgba(3,16,36,.82) 99%);
        }
        .dialog_wrap{
            border: 1px solid rgba(69,168,252,.8);
            overflow: hidden;
            position: fixed;
            left: 50%;
            top: 50%;
            // z-index: 103;
            transform: translate(-50%,-50%);
            background-color: rgba(0,21,62,1);
            border-radius: 2px;
            display: flex;
            flex-direction: column;
            box-shadow: 0 0 32px rgba(49,141,255,.8) inset;
            .dialog_header{
                flex: none;
                height: 60px;
                position: relative;
                background: url('~@/assets/images/dialog/dialog_head_bg.png') no-repeat center/100% 60px;
                .dialog_title{
                    // margin-left: 20px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // text-align: center;
                    img{
                        height: 30px;
                    }
                    span{
                        font-size: 26px;
                        font-weight: 700;
                        // margin-left: 10px;
                        color: #fff;
                        overflow: hidden;
                        text-overflow:ellipsis;
                        white-space: nowrap;
                        padding: 0 50px;
                        box-sizing: border-box;
                    }
                }
                .dialog_close{
                    position: absolute;
                    color: #45a7fb;
                    right: 15px;
                    top:10px;
                    width: 38px;
                    height: 38px;
                    border: 2px solid #45a7fb;
                    cursor: pointer;
                    font-size: 44px;
                    font-weight: normal;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-bottom: 5px;
                    box-sizing: border-box;
                }
            }
            .dialog_body{
                flex: 1;
                overflow-y: auto;
            }
            ::-webkit-scrollbar {
                display: none;
            }
        }
    }
</style>